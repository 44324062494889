<template>
  <ion-page>
    <ion-content size-sm="8">
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <ion-item color="primary" style="margin-top: 40px">
            <ion-label style="text-align: center"
              >Select an app to run.</ion-label
            >
          </ion-item>
          <ion-card>
            <ion-card-content>
              <ion-grid
                v-if="availableApps.length != 0"
              >
                <ion-row>
                  <ion-col
                    class="appCol"
                    v-for="app of availableApps"
                    :href="'/app/' + app.id"
                    detail="true"
                    :key="app.id"
                  >
                    <div class="appEntry" @click="gotoApp(app.id)">
                      <img
                        class="appImage"
                        v-if="app.image_url"
                        :src="app.image_url"
                      />
                      <h2 class="appTitle">
                        {{ app.name }}
                      </h2>
                      <p class="appDescription">{{ app.description }}</p>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonGrid, 
  IonRow, 
  IonCol, 
  alertController 
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { getAvailableApps } from "@/supabase/app";

const availableApps = ref([]);
const router = useRouter();

onMounted(async () => {
  availableApps.value = await getAvailableApps();
  
  if(availableApps.value.length == 0){
    const userApps = await alertController.create({
      message: "Sorry, for you there are no apps assigned.",
      buttons: ["OK"],
    });
    await userApps.present();
 }
});

function gotoApp(appId) {
  router.push(`/app/${appId}`);
}
</script>

<style scoped>
.appCol {
  display: flex;
  justify-content: center;
}
.appEntry {
  color: var(--ion-text-color);
  aspect-ratio: 1/1;
  /*background-color: #00000055;*/
  background-color: #817f7faa;
  border-radius: 15px;
  width: 200px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  border: 1px solid #716f6f;
}

.appEntry:hover {
  /*background-color: #000000aa;*/
  background-color: #9d9d9daa;
}

.appTitle {
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
}
.appImage {
  max-height: 80px;
  width: 100%;
  object-fit: contain;
  border-radius: 15px;
}

.appDescription {
  text-align: center;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  margin: 0;
}

@media (prefers-color-scheme: dark) {
  .appEntry {
    background-color: #5d7555c0;
    border: 1px solid #e6e6e6;
  }
  .appEntry:hover {
    background-color: #45563fd7;
  }
}
</style>
