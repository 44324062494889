import { supabase } from "@/supabaseClient";

const getAvailableApps = async () => {
  const {data, error} = await supabase
        .from("apps")
        .select("*")
        .order('name', { ascending: true });
  if (error) {
    console.error(error);
    return [];
  }
  return data;
}

const getAppsForUser = async ( userId ) => {
  const {data, error} = await supabase
        .from("apps")
        .select("*")
        .contains("users", [userId])
        .order('name', { ascending: true });
  if (error) {
    console.error(error);
    return [];
  }
  return data;
}

const getAppById = async ( appId ) => {


  const {data, error} = await supabase
        .from("apps")
        .select("*")
        .eq("id", appId )
        .limit(1);
  if (error) {
    console.error(error);
    return {};
  }
  return data[0];
}

export { getAppsForUser, getAppById, getAvailableApps };
