import { storage } from "@/storage";
import { pinia } from "@/store/pinia";

import { createApp } from "vue";
import VueNumerals from "vue-numerals";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonPage,
  IonContent,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonInput,
  IonItem,
  IonLabel
} from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Bootstrap icons */
import "bootstrap-icons/font/bootstrap-icons.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/custom.css";

async function mountApp() {
  console.log("Doing everything in order...");

  console.log("Storage loaded", storage);

  console.log("Creating app");
  const app = createApp(App)
    .use(IonicVue)
    .use(VueNumerals)
    .use(router)
    .use(pinia);

  app.component('IonCard', IonCard)
  .component('IonCardHeader', IonCardHeader)
  .component('IonCardContent', IonCardContent)
  .component('IonCardTitle', IonCardTitle)
  .component('IonCardSubtitle', IonCardSubtitle)
  .component('IonButtons', IonButtons)
  .component('IonButton', IonButton)
  .component('IonIcon', IonIcon)
  .component('IonPage', IonPage)
  .component('IonContent', IonContent)
  .component('IonHeader', IonHeader)
  .component('IonFooter', IonFooter)
  .component('IonToolbar', IonToolbar)
  .component('IonTitle', IonTitle)
  .component('IonBackButton', IonBackButton)
  .component('IonInput', IonInput)
  .component('IonItem', IonItem)
  .component('IonLabel', IonLabel);


  await router.isReady();
  console.log("Router ready...");
  app.mount("#app");
  console.log("App mounted.");
  console.log("Everything done.");
}

mountApp();
