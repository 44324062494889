<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        {{ props.modelTitle }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-grid v-if="servingInProgress">
        <ion-row>
          <ion-col>
            <ion-spinner />
          </ion-col>
        </ion-row>
        <ion-loading :is-open="servingInProgress" message="Applying model, please wait..." spinner="crescent" />
      </ion-grid>
      <ion-grid v-if="!modelAndWasmArtefactsSaved && !loadingModelAndWasmArtefactsInProgress">
        <p>Local environment is not ready. Please, download the model:</p>
      </ion-grid>
      <ion-grid v-if="loadingModelAndWasmArtefactsInProgress && !modelAndWasmArtefactsSaved">
        <ion-row>
          <ion-col>
            <ion-spinner />
            <p>Downloading model</p>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-if="loadingModelAndWasmArtefactsInProgress && modelAndWasmArtefactsSaved">
        <ion-row>
          <ion-col>
            <ion-spinner />
            <p>Initializing environment</p>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-if="predictionInfoList.length === 0 && !servingInProgress && modelAndWasmArtefactsSaved && !loadingModelAndWasmArtefactsInProgress">
        <p>Waiting for data.</p>
      </ion-grid>
      <ion-grid v-if="predictionInfoList.length !== 0 && !servingInProgress">
        <p>Model Prediction:</p>
        <ion-row>
          <ion-col size="auto" v-for="(prediction, index) in predictionInfoList" :key="index">
            <div class="prediction-cell">
              <div>{{ prediction.name.replace(/_/g, ' ') }}</div>
              <div v-if="prediction.unit !== undefined">{{ prediction.unit }}</div>
              <div>{{ prediction.score }}</div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
    <ion-grid v-if="!modelAndWasmArtefactsSaved && !loadingModelAndWasmArtefactsInProgress">
        <ion-button fill="clear" @click="downloadEnvironment">Download</ion-button>
    </ion-grid>
  </ion-card>
</template>

<style scoped>
.prediction-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc;
  /* Adds a border to each cell */
  min-height: 5px;
  /* Adjust height as necessary */
  text-align: center;
  /* Centers text within items */
}
</style>

<script setup>
import Web3 from "web3";
import { IonLoading, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonSpinner, IonCardTitle, IonCardContent } from "@ionic/vue";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { events } from "@/utils/events";
import * as ort from '@compolytics/onnxruntime-web';

import { useFileStore } from "@/store/file";
import { useAppStateStore  } from "@/store/app";
import { downloadFile } from "@/supabase/file";

const fileStore = useFileStore();

// List of Predictions
const predictionInfoList = ref([]);
// Serving in progress indicator flag
const servingInProgress = ref(false);
// ort session
const session = ref(null);
// All files required for local inference are loaded flag
const modelAndWasmArtefactsSaved = ref(true)
// Loading files required for local inference in progress indicator flag
const loadingModelAndWasmArtefactsInProgress = ref(false)

// Define the props for the component which are default values
const props = defineProps({

  // What message to listen for
  modelTitle: {
    type: String,
    default: "Model Serving",
  },

  // What to display from the response of the model
  predictionFieldName: {
    type: String,
    default: "",
  },

  // What message to listen for
  dataMessage: {
    type: String,
    default: "data",
  },

  // Supabase bucket name
  bucketName: {
    type: String,
    default: "cicada-example",
  },

  // model.onnx filename in supabase bucket
  model: {
    type: String,
    default: "",
  },

  // name of the output of the ONNX model to be displayed
  outputName: {
    type: String,
    default: "",
  },

  // What to display from the response of the model
  predictionDisplayName: {
    type: String,
    default: "",
  },

  // What units to show for the prediction
  predictionDisplayUnits: {
    type: String,
    default: "",
  },

});

// Mappings of initial URLs to wasm files. .wasm files will be accessed with other links.
const initialWasmUrls = {
  'ort-wasm.wasm': require("@/assets/wasm/ort-wasm.wasm"),
  'ort-wasm-threaded.wasm': require("@/assets/wasm/ort-wasm-threaded.wasm"),
  'ort-wasm-simd.wasm': require("@/assets/wasm/ort-wasm-simd.wasm"),
  'ort-wasm-simd-threaded.wasm': require("@/assets/wasm/ort-wasm-simd-threaded.wasm")
};

// The model is stored as model.onnx 
const initialModelUrl = {
  'model.onnx': props.model
}

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
          const base64data = reader.result.split(',')[1]; // remove the prefix "data:application/wasm;base64,"
          resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
  });
}

// function used to download files, encode them to base64 string and save locally
async function downloadAndSave(localfileName, url, type, downloadFromCloud=false) {
  let response;
  if (downloadFromCloud)
  {
    response = await downloadFile(props.bucketName, props.model);
    console.log(props.bucketName);
    console.log(props.model);
  }
  else
    response = await fetch(url);
  const buffer = await response.arrayBuffer();
  const blob = new Blob([buffer], { type: type });
  const base64Data = await blobToBase64(blob);

  await fileStore.saveFile(localfileName, base64Data,{
    user:undefined,
    app: useAppStateStore().id
  });
}

// function to get URL to local file, used to initialize wasmPaths. Needed because direct assignment of ort.env.wasmPaths to the ressources did not work.
async function getURLtoLocalFile(fileName, type) {
  const base64string = await fileStore.getFile(fileName);

  const byteCharacters = atob(base64string);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: type });
  return URL.createObjectURL(blob);
}

// function to read local file as ByteArray, used to read .onnx model
async function getByteArrayFromLocalFile(fileName) {
  const base64string = await fileStore.getFile(fileName);

  const byteCharacters = atob(base64string);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return byteArray;
}

// checks if all required .wasm files and .onnx model are available locally
async function areAllFilesSaved() {
  for (const fileName of Object.keys(initialWasmUrls)) {
      const fileExistsLocally = await fileStore.fileExists(fileName);
      if (!fileExistsLocally) {
          return false;
      }
  }
  const fileExistsLocally = await fileStore.fileExists(Object.keys(initialModelUrl)[0]);
  if (!fileExistsLocally) {
    return false;
  }
  return true;
}

// function to load all required .wasm and .onnx files
async function downloadEnvironment() {
  loadingModelAndWasmArtefactsInProgress.value = true

  // downloading and saving .wasm artefacts
  for (const [fileName, bucketFilename] of Object.entries(initialWasmUrls)) {
      const fileExistsLocally = await fileStore.fileExists(fileName);

      if (!fileExistsLocally) {
          await downloadAndSave(fileName, bucketFilename, 'application/wasm', false);
      }
  }
  
  // downloading and saving .onnx model
  const [fileName, bucketFileName] = Object.entries(initialModelUrl)[0];

  const fileExistsLocally = await fileStore.fileExists(fileName);

  if (!fileExistsLocally) {
      await downloadAndSave(fileName, bucketFileName, 'application/octet-stream', true);
  }

  // initialize environment
  await initEnvironment();

  modelAndWasmArtefactsSaved.value = true;
  loadingModelAndWasmArtefactsInProgress.value = false
}

// function to initialize onnxruntime-web environment
async function initEnvironment() {
  loadingModelAndWasmArtefactsInProgress.value = true
  
  //initializing ort.env.wasm.wasmPaths
  const wasmPaths = {};

  for (const [fileName, url] of Object.entries(initialWasmUrls)) {
      wasmPaths[fileName] = await getURLtoLocalFile(fileName, 'application/wasm');
  }

  ort.env.wasm.wasmPaths = wasmPaths;
  
  //initializing session
  const fileName = Object.entries(initialModelUrl)[0][0];
  const model = await getByteArrayFromLocalFile(fileName);
  session.value = await ort.InferenceSession.create(model);

  loadingModelAndWasmArtefactsInProgress.value = false
}


onMounted(async () => {
  // Setup event listener for data messages 
  events.on(props.dataMessage, onData);

  // for test only
  /*await fileStore.deleteFile('model.onnx');
  await fileStore.deleteFile('ort-wasm.wasm');
  await fileStore.deleteFile('ort-wasm-threaded.wasm');
  await fileStore.deleteFile('ort-wasm-simd-threaded.wasm');
  await fileStore.deleteFile('ort-wasm-simd.wasm');*/

  if (await areAllFilesSaved()) {
    await initEnvironment();
  }
  else {
    modelAndWasmArtefactsSaved.value = false;
  }
});

onBeforeUnmount(() => {
  events.off(props.dataMessage, onData);
});

function processPredictions(props, predicted_values) {
  const predictionList = []; // Assuming this is the initial setup

  let renameTable = {};
  try
  {
    renameTable = JSON.parse(props.predictionDisplayName);
  } catch (error) {
    renameTable = {};
  }

  let unitTable = {};
  try
  {
    unitTable = JSON.parse(props.predictionDisplayUnits);
  } catch (error) {
    unitTable = {};
  }

  // Ensure predictionFieldName is available and a string
  if (!props || typeof props.predictionFieldName !== 'string') {
    return []; // or handle the error appropriately
  }

  const predFieldList = props.predictionFieldName.split(',').map(name => name.trim());
  const fieldName = predFieldList[0];

  const displayFieldName = renameTable[fieldName] ? renameTable[fieldName] : fieldName;
  const displayUnitName = unitTable[fieldName] ? "[" + unitTable[fieldName] + "]" : undefined;
  const prediction = {
    name: displayFieldName,
    score: predicted_values[0].toFixed(2),
    unit: displayUnitName
  };
  predictionList.push(prediction);

  return predictionList;
}

async function onData(data) {

  if (!modelAndWasmArtefactsSaved.value){
    return;
  }

  // Set serving flag as working
  servingInProgress.value = true;
  // Clear the prediction list
  predictionInfoList.value = [];

  // Hack, we need to remove field calibration if it is any empty list
  // -----------------------------------------------------------------
  // This should be done in the model, but older version are deployed with not ignoring calibration when empty
  if (data.calibration && data.calibration.length == 0) {
    delete data.calibration;
  }

  // Lets turn this into a json request
  const inputData = JSON.stringify(data)
  console.log(data)

  try {
    const inputTensor = new ort.Tensor('string', [inputData], [1]);
    const output = await session.value.run({ 
      input: inputTensor,
    });

    predictionInfoList.value = processPredictions(props, output[props.outputName].data);

    data.prediction = {};
    data.prediction.values = output.output_1.data[0];

    events.emit("prediction", data);
  }
  finally {
    servingInProgress.value = false;
  }
}

</script>

<style></style>