import { defineStore } from "pinia";
import { ref } from "vue";

const storeId = "app";

export const useAppStateStore = defineStore(storeId, () => {
  const loaded = ref(false);
  const name = ref(undefined);
  const id = ref(undefined);
  const raw = ref(undefined);
  function loadApp( app ) {
    loaded.value = true;
    name.value = app.name;
    id.value = app.id;
    raw.value = app;
  }
  function unloadApp() {
    loaded.value = false;
    name.value = undefined;
    id.value = undefined;
    raw.value = undefined;
  }

  return { id, raw, loaded, name, loadApp, unloadApp };
});
